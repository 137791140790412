var func = {
  // 获取url指定的参数
  getUrlParam(name) {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    const r = window.location.search.substr(1).match(reg)
    if (r != null) {
      return unescape(r[2])
    }
    return null
  },
  formatDate(date, fmt) {
    /**
     * date:需要格式化的日期Date()类型
     * fmt：日期格式 yyyy-MM-dd
     * @param  {[Date,str]} y [description]
     * @return {[type]}   [description]
     */
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    }
    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + ''
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length))
      }
    }
    return fmt
  },
  getAge(strBirthday) {
    let returnAge;
    let strBirth = new Date(strBirthday)
    let birthYear = strBirth.getYear();
    let birthMonth = strBirth.getMonth() + 1;
    let birthDay = strBirth.getDate();
    let d = new Date();
    let nowYear = d.getYear();
    let nowMonth = d.getMonth() + 1;
    let nowDay = d.getDate();
    if (nowYear == birthYear) {
      returnAge = 0; //同年 则为0岁
    } else {
      let ageDiff = nowYear - birthYear; //年之差        	
      if (ageDiff > 0) {
        if (nowMonth == birthMonth) {
          let dayDiff = nowDay - birthDay; //日之差  
          if (dayDiff < 0) {
            returnAge = ageDiff - 1;
          } else {
            returnAge = ageDiff;
          }
        } else {
          let monthDiff = nowMonth - birthMonth; //月之差                	
          if (monthDiff < 0) {
            returnAge = ageDiff - 1;
          } else {
            returnAge = ageDiff;
          }
        }
      } else {
        returnAge = -1; //返回-1 表示出生日期输入错误 晚于今天      
      }
    }
    return returnAge; //返回周岁年龄	
  },
  /**
 * 函数防抖 
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
  Debounce(fn, t) {
    let delay = t || 500;
    let timer;
    return function () {
      let args = arguments;
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        timer = null;
        fn.apply(this, args);
      }, delay);
    }
  },
  /**
  1. 函数节流
  2. @param fn
  3. @param interval
  4. @returns {Function}
  5. @constructor
  */
  Throttle(fn, t) {
    let last;
    let timer;
    let interval = t || 500;
    return function () {
      let args = arguments;
      let now = +new Date();
      if (last && now - last < interval) {
        clearTimeout(timer);
        timer = setTimeout(() => {
          last = now;
          fn.apply(this, args);
        }, interval);
      } else {
        last = now;
        fn.apply(this, args);
      }
    }
  }

}
export default func
