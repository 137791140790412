import Vue from 'vue'
import Router from 'vue-router'
// import store from '../store'
// import api from "@/api/api";
// import { Dialog, Notify, Toast } from 'vant' // 错误时提示UI
import { Popup } from 'vant';

Vue.use(Popup)
Vue.use(Router)
var router = new Router({
  mode: 'history',
  // base: '/',
  routes: [
    // 代理人端
    {
      path: '/',
      name: 'select',
      component: () => import('@/views/select')
    },
    {
      path: '/Agent',
      name: 'Index',
      component: () => import('@/views/Agent')
    },
    // 客户登录登录页
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login')
    },
    // 客户端
    {
      path: '/Customer',
      name: 'Customer',
      component: () => import('@/views/Customer')
    },
  ]
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
