const state = {
  // 是否登录了
  isLogin: false,
  accessToken:null,
  userInfo:null,
}

const mutations = {
  isLogin: (state, login) => {
    state.isLogin = login
  },
  accessToken: (state, accessToken) => {
    state.accessToken = accessToken
  },
  userInfo: (state, userInfo) => {
    state.userInfo = userInfo
  },
}

export default {
  namespaced: true,
  state,
  mutations
}
