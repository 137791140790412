import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import Vconsole from 'vconsole'
import NoSleep from 'nosleep.js';
import 'vant/lib/index.css';
// 引入公共的方法
import func from './utils/public'
// 判断环境
const isWechat = () => {
  return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) == 'micromessenger'
}
const isQyWechat = () => {
  return String(navigator.userAgent.toLowerCase().match(/wxwork/i)) == 'wxwork'
}
Vue.use(Vant);
Vue.prototype.$isWx = isWechat()
Vue.prototype.$isQyWx = isQyWechat()
// 挂载全局共用的方法
Vue.prototype.$func = func
Vue.prototype.$NoSleep = NoSleep;
// 调试模式 
// console.log(window.globalConfig, 'window.globalConfi');
// if (window.globalConfig && window.globalConfig.VCONSOLE) {
// new Vconsole()
// }

// Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
